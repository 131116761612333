import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { FaAngleDown } from 'react-icons/fa';
import CardSubMenu from 'src/components/cards/card-sub-menu';
import styled from 'styled-components';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('mouseleave', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('mouseleave', listener);
    };
  }, [ref, handler]);
}

const NavDesktop = ({ data }) => {
  const router = useRouter();
  const cid = router.asPath;
  const queryCid = router.query.cid;
  const [subToggle, setSubToggle] = useState({
    status: false,
    key: null
  });

  let cloneNavData = [];
  let newNavData = [];
  if (data !== null) {
    cloneNavData = [...data];
    newNavData = cloneNavData.splice(0, 3);
  }
  const subBoxRef = useRef();

  useOnClickOutside(subBoxRef, async () => {
    await setSubToggle({
      status: false,
      key: null
    });
  });

  return (
    <>
      <NavDesktopStyle>
        <div className='menu container'>
          <div className='border-bottom'>
            <ul className='nav-menu'>
              <li className={`${cid === '/' ? '--active' : ''} active-menu-desktop`}>
                <a
                  aria-label='nav-desktop'
                  title='หน้าแรก'
                  href='/'
                  //! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      position: 'on:header',
                      section: 'header',
                      data: {
                        title: 'หน้าแรก',
                        heading: 'หน้าแรก'
                      }
                    })
                  }
                >
                  <span> หน้าแรก </span>
                </a>
              </li>
              {Array.isArray(newNavData) &&
                newNavData.map((menu, index) => (
                  <li
                    key={index}
                    className={`${cid === `/${menu?.link}` || queryCid === menu?.nameEng ? '--active' : ''} active-menu-desktop`}
                    onMouseLeave={() => setSubToggle({ status: false, key: null })}
                  >
                    <a
                      href={`/${menu?.link}`}
                      onMouseOver={() => setSubToggle({ status: true, key: index })}
                      onFocus={() => {}}
                      title={menu?.nameTh}
                      //! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          position: 'on:header',
                          section: 'header',
                          data: {
                            title: menu?.nameTh,
                            heading: menu?.nameTh
                          }
                        })
                      }
                    >
                      <span> {menu?.nameTh} </span>
                      {menu?.sub.length > 0 && <FaAngleDown />}
                    </a>
                    {/* --------------------------------- SUBMENU -------------------------------- */}
                    {subToggle?.key === index && subToggle?.status && menu?.sub.length > 0 && (
                      <ul className={`nav-sub-menu${[0, 1].includes(index) ? ' --left' : ' --right'}`} ref={subBoxRef} onMouseLeave={() => setSubToggle({ key: null, status: false })}>
                        <CardSubMenu
                          data={menu.sub}
                          //! DATALAYER
                          heading={menu?.nameTh}
                          eventDataLayer={{
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router?.pathname,
                            position: 'ใน:menubar',
                            section: 'header'
                          }}
                        />
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </NavDesktopStyle>
    </>
  );
};

const NavDesktopStyle = styled.div`
  position: absolute;
  width: 100%;
  background-color: #fff;
  /* ------------------------------- DESKTOP : Navigation Menu -------------------------------- */
  ul.nav-menu {
    flex-flow: unset !important;

    li.active-menu,
    .active-menu-desktop {
      position: relative;
      padding: 12px 8px;
      width: 25%;
      &:hover {
        background: #fff;
      }
      a {
        /* display: flex;
        align-items: center;
        justify-content: center; */
        font-size: 14px;
        font-weight: 300;
        span {
          font-weight: bold;
          font-size: 20px;
        }
        svg {
          font-size: 20px;
          margin: 0px 0px -5px 10px;
        }
      }
    }
  }

  /* ---------------------------- DESKTOP : Navigation SubMenu ------------------------------ */
  ul.nav-sub-menu {
    background: rgb(0, 2, 28, 0.95);
    /* border: 1px solid #00ff57; */
    border-radius: 3px;
    width: 600px;
    position: absolute;
    visibility: visible;
    z-index: 99;
    padding: 0px;
    margin: 0px;
    opacity: 1;
    transition: all 0.3s ease-out;
    transform-origin: 0 0 0;
    /* box-shadow: 0 6px 9px -6px #00ff57; */
    box-shadow: #00ff57 0px 10px 10px -10px;
    &.custom-nav-top {
      left: 0px;
      top: 39px !important;
    }
    li.active-sub-menu {
      margin: 0;
      padding: 0;
    }
    a {
      font-size: 14px;
      font-weight: 300;
      display: block;
      text-transform: uppercase;
      transition: all 0.5s ease-out;
    }
    &.--right {
      right: 0;
    }
    &.--left {
      left: 0;
    }
  }
`;

export default NavDesktop;
