import _ from 'lodash';
import { useState } from 'react';
import CardHNav from 'src/components/cards/card-h-nav';
import styled from 'styled-components';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
import { APP_IMG } from 'src/constants';
const CardSubMenu = ({ data, eventDataLayer, heading }) => {
  if (_.isEmpty(data)) return null;
  const router = useRouter();
  const [articles, setArticles] = useState({
    key: null,
    data: data[0]?.data,
    link: data[0]?.link
  });

  return (
    <>
      <CardSubMenuStyle>
        <div className='row card-sub-menu'>
          <div className='col-4'>
            <ul>
              {!_.isEmpty(data) &&
                data.map((item, index) => (
                  <li
                    key={index}
                    aria-hidden='true'
                    onMouseOver={() => {
                      setArticles({
                        key: index,
                        data: item.data,
                        link: item.link
                      });
                    }}
                    onFocus={() => {}}
                  >
                    <a
                      title={item.nameTh}
                      href={item.link}
                      //! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          ...eventDataLayer,
                          data: {
                            title: item?.nameTh,
                            heading: `${heading}:${item?.nameTh}`
                          }
                        })
                      }
                    >
                      {item.nameTh}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className='col-8 card-sub-menu-content'>
            {!_.isEmpty(articles) ? (
              <>
                {articles.data.map((item, index) => (
                  <CardHNav
                    key={index}
                    data={item}
                    path={item.link}
                    eventDataLayer={{
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      position: 'ใน:menubar',
                      section: 'header',
                      data: {
                        title: item?.title,
                        heading: `${heading}:${item?.subCategory?.th}`
                      }
                    }}
                  />
                ))}
                <button className='btn-readmore'>
                  <a className='see-all' title='see all' href={articles?.link}>
                    SEE ALL
                  </a>
                </button>
              </>
            ) : (
              <div className='empty'>
                <img className='logo-img' src={`${APP_IMG}/images/cropped-khobsanam-logo-1.png`} loading='lazy' alt='logo-mobile' title='logo-mobile' width='100px' height='100px' />
              </div>
            )}
          </div>
        </div>
      </CardSubMenuStyle>
    </>
  );
};

const CardSubMenuStyle = styled.div`
  position: relative;
  .card-sub-menu {
    padding: 15px 25px 15px 15px;
    ul {
      overflow: hidden scroll;
      height: 300px;
      list-style: none;
      padding: 0;
      display: block;
      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgb(0, 2, 28, 0.5);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #ffffff21;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #00ff57;
      }

      li {
        padding: 5px;
        border-radius: 3px;
        margin-bottom: 16px;
        margin-left: 0;
        text-align: left;
        border: 2px;
        a {
          color: #fff !important;
          &:hover {
            color: #00ff57 !important;
          }
        }
        i {
          float: right;
          color: transparent;
        }
        &:hover {
          color: #fff !important;
          border: unset;
          i {
            color: #fff !important;
          }
        }
      }
    }

    &.--small {
      img {
        width: 54px;
        height: 54px;
      }
      h3 {
        font-size: 14px;
        line-height: 1.4;
      }
      p {
        font-size: 12px !important;
        font-weight: 400;
        margin: 0;
      }
    }

    .card-sub-menu-content {
      position: relative;
      .thumbnail a {
        picture {
          width: 110px;
          object-fit: cover;
          background-size: cover;
          background-position: center center;
        }
      }
      .empty {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        transform: translate(100px, 0px);
        display: flex;
        justify-items: center;
        align-items: center;
        .logo-img {
          width: 50%;
          height: 50%;
          opacity: 0.7;
        }
      }

      button.btn-readmore {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        background-color: #00ff57;
        border-radius: 3px;
        border: 1px solid #00ff57;
        padding: 8px 10px;
        transition: 0.25s ease-in-out 10ms;

        a.see-all {
          font-weight: 600;
        }
        &:hover {
          border: 1px solid #00ff57;
          background-color: rgb(0, 2, 28, 0.95);
          a.see-all {
            color: #00ff57;
          }
        }
      }
    }
  }
`;
export default CardSubMenu;
