import { FaRegCalendarAlt } from 'react-icons/fa';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import Image from 'src/components/image';
import { useDateUpdate } from 'src/utils/helper';
import styled from 'styled-components';

const CardHNav = ({ data, path, eventDataLayer }) => {
  const badge = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  if (!data) return null;
  const dateUpdate = useDateUpdate(data?.published_at, true);
  return (
    <CardHNavStyle>
      <div className='row align-items-center'>
        <div className='col-4 thumbnail'>
          <a
            title={data.title}
            href={path}
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer
              })
            }
          >
            <Image image={data.image} title={data.title} />
          </a>
        </div>
        <div className='col-8 title'>
          <a
            href={path}
            //! DATALAYER
            onClick={() =>
              sendDataLayerGlobal({
                ...eventDataLayer
              })
            }
            title={data.title}
          >
            <h3>{data.title}</h3>
          </a>
          <div className='align-items-left'>
            <FaRegCalendarAlt size={12} />
            <span>{dateUpdate}</span>
          </div>
        </div>
      </div>
    </CardHNavStyle>
  );
};

const CardHNavStyle = styled.div`
  display: flex;
  width: 100%;
  min-height: 90px;
  .resolution-image {
    box-shadow: rgba(54, 54, 54, 0.4) 0px 2px 3px 0px;
    padding-top: 65% !important;
  }
  .align-items-center {
    align-items: center;
  }
  .align-items-left {
    text-align: left;
  }
  .thumbnail {
    padding: 0px;
    img {
      object-fit: cover;
    }
  }
  .title {
    padding: 5px 0px 5px 15px;
    /* padding-top: 5px;
      padding-bottom: 5px; */
    h3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.75;
      color: #fff;
      margin: 0px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
      width: 236px;
    }
    svg {
      width: 10px;
      margin-left: 0;
      color: #00da49 !important;
    }
    span {
      font-size: 12px;
      margin-left: 5px;
      color: #00da49;
    }
  }
  &:hover {
    h3,
    a,
    svg,
    span {
      color: #00da49 !important;
    }
  }
`;

export default CardHNav;
